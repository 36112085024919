import $ from 'jquery';

$(function() {
	$(document.body).on('click', '.post .pin', function() {
		var $card = $(this).closest('.post');
		var $icon = $(this).find('.fa-star');
		var token = $('input[name="token"]').val();
		var id = $(this).closest('.post').data('id');
	
		var url = $card.data('pinned') ? '/posts/unpin' : '/posts/pin';
	
		$.post({
			url: url,
			data: {
				favorite: id,
				token: token
			},
			success: function() {
				if($card.data('pinned')) {
					$icon.attr('data-prefix', 'far');
					$card.attr('data-pinned', 0);
				} else {
					$icon.attr('data-prefix', 'fas');
					$card.attr('data-pinned', 1);
				}
			},
			error: function(req) {
				$('.notices').addNotice(req.responseJSON.message, 'danger');
			}
		})
	});

	var fetching = false;
	$('.posts-inner').on('scroll', function() {
		var $self = $(this);
		var type = $self.find('.tab-pane:visible').data('type');
		var $spinner = $('.posts .spinner');
		
		if(!fetching && type && $spinner.is(':visible') && ($self.get(0).scrollHeight - $self.scrollTop() == $self.outerHeight() - 1)) {
			$.get({
				url: '/posts/get_posts',
				data: {
					token: $('input[name="token"]').val(),
					offset: $('.posts .post').length
				},
				success: function(res) {
					if(!res.posts || res.posts.length < 1) {
						$spinner.hide();
					} else {
						$spinner.before(res.posts);
					}
				},
				error: function() {
					$spinner.hide();
				},
				complete: function() {
					fetching = false;
				}
			})
		}
	});

	$(document.body).on('show.bs.modal', '#postModal', function(e) {
		var $button = $(e.relatedTarget);
		var id = $button.closest('.post').data('id');
		var user = $button.closest('.post').find('.author').text();
	
		$(this).find('.modal-description .user-name').text(user);
		$(this).find('input[name="post_id"]').val(id);
	});

	$(document.body).on('submit', '#postModal form', function(e) {
		e.preventDefault();
		var data = $(this).serializeObject();
		var $button = $('#postModal #modalSubmit');
		$button.prop('disabled', true);
	
		$.post({
			url: '/posts/send_reply',
			data: {
				post_id: data.post_id,
				message: data.message,
				token: data.token
			},
			success: function() {
				$button.siblings('.text-success').fadeIn();
				setTimeout(function() {
					$('#postModal').modal('hide');
					$button.prop('disabled', false);
					$button.siblings('.text-success').hide();
				}, 2000);
			},
			complete: function() {
				$('#postModal').find('textarea').val('');
			}
		});
	});
});