import $ from 'jquery';

$(document.body).on('show.bs.modal', '#requestModal', function(e) {
	var $button = $(e.relatedTarget);
	var data = $button.data('request');
	var $modal = $(this);
	
	$modal.find('.modal-title').text(data.user.first_name + ' ' + data.user.last_name);
	$modal.find('input[name="request_id"]').val(data.id);
	$modal.find('.user-name').text(data.user.first_name);
});

$(document.body).on('click', '.request-card .pin', function() {
	var $card = $(this).closest('.request-card');
	var $icon = $(this).find('.fa-star');
	var token = $('input[name="token"]').val();

	var url = $card.data('pinned') ? '/advice/unpin' : '/advice/pin';

	$.post({
		url: url,
		data: {
			favorite: $card.data('id'),
			token: token
		},
		success: function() {
			if($card.data('pinned')) {
				$icon.attr('data-prefix', 'far');
				$card.attr('data-pinned', 0);
			} else {
				$icon.attr('data-prefix', 'fas');
				$card.attr('data-pinned', 1);
			}
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	});
});