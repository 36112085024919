import Dropzone from 'dropzone';
import $ from 'jquery';

Dropzone.options.dz = {
	paramName: 'file',
	maxFilesize: 5,
	timeout: 5000,
	uploadMultiple: true,
	maxFiles: 6,
	parallelUploads: 6,
	autoProcessQueue: true,
	// acceptedFiles: '.pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .jpg, .jpeg, .png, .gif, .bmp',
	init: function() {
		this.on('successmultiple', function(file, res) {
			var input = document.getElementById('uploaded_files');
			var values = input.value.length > 0 ? input.value.split(',') : [];
			values = values.concat(res.file_ids);

			input.value = values.join(',');

			res.files.forEach(function(e) {
				$('.files').append(e);
			});

			$('.file-list-empty-message').addClass('d-none');
			$(document).allTooltips();
		});

		this.on('sendingmultiple', function(file, xhr, formData) {
			var token = document.querySelector('input[name="token"]');
			formData.append('token', token.value);
		});
	}
}