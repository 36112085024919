import $ from 'jquery';

// Delete an idea file
$(document.body).on('click', '.idea-file-delete', function(e) {
	e.preventDefault();
	
	var $file = $(this).closest('.idea-file');
	var file_id = $file.data('id');
	var token = $('input[name="token"]').val();

	$file.find('.fa-times').addClass('fa-spin');

	$.post({
		url: '/ideas/delete_file',
		data: {
			file_id: file_id,
			token: token
		},
		success: function() {
			if($file.siblings('.idea-file').length === 0) {
				$file.siblings('.file-list-empty-message').removeClass('d-none');
			}
			$file.remove();
		},
		error: function(req) {
			$file.find('.fa-times').removeClass('fa-spin');
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	})
});

$(document.body).on('click', '.idea-card .pin', function() {
	var $card = $(this).closest('.idea-card');
	var $icon = $(this).find('.fa-star');
	var token = $('input[name="token"]').val();

	var url = $card.data('pinned') ? '/matches/unpin' : '/matches/pin';

	$.post({
		url: url,
		data: {
			favorite: $card.data('id'),
			token: token
		},
		success: function() {
			if($card.data('pinned')) {
				$icon.attr('data-prefix', 'far');
				$card.attr('data-pinned', 0);
			} else {
				$icon.attr('data-prefix', 'fas');
				$card.attr('data-pinned', 1);
			}
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	})
});

$(document.body).on('click', 'h1 .pin', function() {
	if(!$('body').hasClass('ideas-view')) return;

	var $pin = $(this);
	var $icon = $(this).find('.fa-star');
	var token = $('input[name="token"]').val();

	var url = $pin.data('pinned') ? '/matches/unpin' : '/matches/pin';

	$.post({
		url: url,
		data: {
			favorite: $pin.data('idea'),
			token: token
		},
		success: function() {
			if($pin.data('pinned')) {
				$icon.attr('data-prefix', 'far');
				$pin.attr('data-pinned', 0);
			} else {
				$icon.attr('data-prefix', 'fas');
				$pin.attr('data-pinned', 1);
			}
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	})
});

$(document.body).on('show.bs.modal', '#ideaModal', function(e) {
	var $button = $(e.relatedTarget);
	var data = $button.data('idea');

	$('#ideaModal').find('.modal-title').text(data.name);
	$('#ideaModal').find('.modal-description .user-name').text(data.user.first_name);
	$('#ideaModal').find('input[name="idea_id"]').val(data.id);
});