import $ from 'jquery';

$(function() {
	function prev() {
		if(steps === 0) return;
	
		steps -= 1;
		var translate = 'translateX(0)';
		if(steps > 0) {
			translate = 'translateX(calc(-' + (steps * 100) + '% - ' + (steps * 30) + 'px))';
		}
	
		var $deck;
		if($(this).hasClass('prev')) {
			$deck = $(this).closest('.card-deck');
		} else {
			$deck = $(this);
		}
	
		$deck.children('.card').css({
			'-moz-transform': translate,
			'-webkit-transform': translate,
			'transform': translate
		});
	
		if(steps === 0) {
			$deck.children('.prev').prop('disabled', true);
		}
		
		$deck.children('.next').prop('disabled', false);
	}
	
	function next() {
		steps += 1;
		var translate = 'translateX(calc(-' + (steps * 100) + '% - ' + (steps * 30) + 'px))';
	
		var $deck;
		if($(this).hasClass('next')) {
			$deck = $(this).closest('.card-deck');
		} else {
			$deck = $(this);
		}
	
		$deck.children('.card').css({
			'-moz-transform': translate,
			'-webkit-transform': translate,
			'transform': translate
		});
	
		var $last = $deck.children('.card').last();
		if(($last.position().left - 30) <= $last.parent().width()) {
			$deck.children('.next').prop('disabled', true);
		}
		
		$deck.children('.prev').prop('disabled', false);
	}

	var steps = 0;
	
	$('body.profile-favorites').on('click', '.next', next);
	$('body.profile-favorites').on('click', '.prev', prev);

	$('body.profile-favorites .card-deck').each(function() {
		var $last = $(this).children('.card').last();
		if($last.position().left <= $(this).width()) {
			$(this).children('.next, .prev').hide();
		}
	});
});