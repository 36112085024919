import $ from 'jquery';

$(function() {
	$(document.body).on('click', '.profile-image', function() {
		$('input#avatar').trigger('click');
	});

	$(document.body).on('change', '.user-card #avatar', function() {
		var preview = document.querySelector('.profile-image');
		var file = document.querySelector('.user-card #avatar').files[0];
		var reader = new FileReader();
		if(file && (file.size / 1024 / 1024) > 5) {
			return false;
		}

		reader.onloadend = function() {
			preview.style = 'background-image: url(' + reader.result + ');';
		}

		if(file) {
			reader.readAsDataURL(file);
		} else {
			preview.style = "";
		}
	});

	$(document.body).on('click', '.profile-links .btn.add', function() {
		var $parent = $(this).closest('.link');
		var $link = $parent.clone(false);

		$parent.find('.btn.add').addClass('d-none');
		$parent.find('.btn.remove').removeClass('d-none');
		$('.profile-links').append($link);
		$('.profile-links').find('.link input').last().val('');
		$(document).allTooltips();
	});

	$(document.body).on('click', '.profile-links .btn.remove', function() {
		var $parent = $(this).closest('.link');
		$parent.find('.btn').tooltip('dispose');
		$parent.remove();
	});
});