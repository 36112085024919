import $ from 'jquery';

$('body.auth-register').on('change', 'input[name="role"]', function() {
	if($(this).val() === 'cofounder') {
		$('.partner-role-options').hide();
		$('.member-role-options').show();
		$('.investor-level-wrapper').hide();
		$('.investor-level-wrapper select').prop('disabled', true);
	} else {
		$('.partner-role-options').show();
		$('.member-role-options').hide();
	}

	$('input[name="roles[]"]').prop('checked', false);
});

$('body.auth-register').on('change', 'input[name="avatar"]', function() {
	if(typeof FileReader === 'undefined') {
		return;
	}

	var reader = new FileReader();
	var $self = $(this);

    reader.onload = function(e) {
		var $img = $('<img src="' + e.target.result + '">');
		$('.avatar-thumbnail').empty();
        $('.avatar-thumbnail').append($img);
    };

	var input = $self.get(0);
	if(input.files && input.files[0]) {
		reader.readAsDataURL(input.files[0]);
	}
});

$('body.auth-register').on('change', '#investor-checkbox', function() {
	if($(this).is(':checked')) {
		$('.investor-level-wrapper').show();
		$('.investor-level-wrapper select').prop('disabled', false);
	} else {
		$('.investor-level-wrapper').hide();
		$('.investor-level-wrapper select').prop('disabled', true);
	}
});

$('body.auth-register').ready(function() {
	if($('input[name="role"]:checked').val() === 'cofounder') {
		$('.member-role-options').show();
	} else {
		$('.partner-role-options').show();
	}
});

$('body.auth-register').on('submit', '.form-register', function() {
	var data = $(this).serializeObject();

	if(data.action === 'register1' && (typeof data.roles === 'undefined' || data.roles.length === 0)) {
		$('.notices').empty();
		$('.notices').addNotice('Please choose both a role and a service level.', 'danger');
		$(window).scrollTop(0);
		return false;
	}
});

$('body.auth-expired').on('submit', '.form-renew', function() {
	$('.btn').prop('readonly', true);
	$('.checkout-spinner').removeClass('d-none');
});