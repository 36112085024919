import $ from 'jquery';

$(function() {
	if(typeof stripe === 'undefined') return;

	var elements = stripe.elements();
	var style = {
		base: {
			fontSize: '16px',
			fontFamily: 'Roboto, Arial, sans-serif'
		},
		complete: {
			color: '#28a745'
		}
	};

	var cardholderName = document.getElementById('cardholder-name');
	var cardButton = document.getElementById('checkout-button');
	var clientSecret = cardButton.dataset.secret;

	if($('#card-number').length > 0) {
		var cardNumber = elements.create('cardNumber', {style: style});
		var cardExpiry = elements.create('cardExpiry', {style: style});
		var cardCvc = elements.create('cardCvc', {style: style});
		
		cardNumber.mount('#card-number');
		cardExpiry.mount('#card-date');
		cardCvc.mount('#card-cvc');
	}

	$('.form-register, .form-renew, .form-upgrade').on('submit', function(e) {
		if(cardButton.dataset.method) {
			processPayment(e, false, clientSecret, cardButton.dataset.method);
		} else {
			processPayment(e, true, clientSecret, {
				card: cardNumber,
				billing_details: { name: cardholderName.value }
			});
		}
	});
});

var processPayment = function(e, newCard, clientSecret, paymentMethod) {
	e.preventDefault();

	var $form = $(e.target);

	$form.find('input').prop('readonly', true);
	$form.find('.btn').prop('disabled', true);
	$form.find('.checkout-spinner').removeClass('d-none');
	var paymentPromise;

	if(newCard) {
		paymentPromise = stripe.confirmCardPayment(clientSecret, {
			payment_method: paymentMethod
		});
	} else {
		paymentPromise = stripe.handleCardPayment(
			clientSecret, {
				payment_method: paymentMethod
			}
		)
	}

	paymentPromise.then(function(result) {
		$('.notices').empty();
	
		if (result.error) {
			$('.notices').addNotice(result.error.message, 'danger');
			$form.find('input').prop('readonly', false);
			$form.find('.btn').prop('disabled', false);
			$form.find('.checkout-spinner').addClass('d-none');
		} else {
			$form.find('input[name="intent"]').val(result.paymentIntent.id);
			if(result.paymentIntent.status === 'succeeded') {
				$form.off(e);
				$form.submit();
			}
		}
	});
}