import $ from 'jquery';

$(document.body).on('show.bs.modal', '#userModalnew', function(e) {
	var $button = $(e.relatedTarget);
	var data = $button.data('user');
	
	$('#userModalnew').find('.modal-title').text(data.first_name + ' ' + data.last_name);
	$('#userModalnew').find('input[name="user_id"]').val(data.id);
	$('#userModalnew').find('.user-name').text(data.first_name);
});

$(document.body).on('click', '.user-card .pin', function() {
	var $card = $(this).closest('.user-card');
	var $icon = $(this).find('.fa-star');
	var token = $('input[name="token"]').val();

	var url = $card.data('pinned') ? '/users/unpin' : '/users/pin';

	$.post({
		url: url,
		data: {
			favorite: $card.data('id'),
			token: token
		},
		success: function() {
			if($card.data('pinned')) {
				$icon.attr('data-prefix', 'far');
				$card.attr('data-pinned', 0);
			} else {
				$icon.attr('data-prefix', 'fas');
				$card.attr('data-pinned', 1);
			}
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	})
});

$(document.body).on('submit', '#userModalnew form', function(e) {
	e.preventDefault();
	var data = $(this).serializeObject();
	var $button = $('#userModalnew #modalSubmit');
	$button.prop('disabled', true);

	$.post({
		url: '/messages/send_message',
		data: {
			user_id: data.user_id,
			message: data.message,
			token: data.token
		},
		success: function() {
			$button.siblings('.text-success').fadeIn();
			setTimeout(function() {
				$('#userModalnew').modal('hide');
				$button.prop('disabled', false);
				$button.siblings('.text-success').hide();
			}, 2000);
		},
		error: function(req) {
			$button.siblings('.text-danger').fadeIn();
			setTimeout(function() {
				$('#userModalnew').modal('hide');
				$button.prop('disabled', false);
				$button.siblings('.text-d').hide();
			}, 2000);
		}
	});
});